import Main from './component/Main';
import Floated from './component/Floated/Floated';

function App() {
  return (
    <Main />
  );
}

export default App;
